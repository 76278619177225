html {
  scroll-behavior: inherit !important;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* Scroll x */
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Scroll y */
/* width */
::-webkit-scrollbar {
  background: #222; 
  width: .6rem;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #aaa; 
  border-radius: 1rem;
  height: 1rem;
  min-height: 1rem;
  width: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}