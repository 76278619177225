.cNavBtn {
  color: #ddddddbd !important;
  background: linear-gradient(90deg, #a64bf455, #33ccff00) !important;
  border-radius: 1rem;
  padding: 2rem 0.5rem;
  font-size: 0.7rem !important;
}

.cNavBtnActive {
  color: #a64bf4 !important;
  font-weight: bold;
  background: linear-gradient(90deg, #a64bf433, #33ccff00) !important;
  border-radius: 1rem;
  padding: 2rem 0.5rem;
  font-size: 0.8rem !important;
}

/* menor a lg cNavBtn :
*/

@media (max-width: 992px) {
  .cNavBtn {
    color: #0009 !important;
  }
}
