.void-icon {
  width: 2.3rem;
  height: 2.3rem;
  border-radius: 50%;
  background: radial-gradient(circle, rgb(255, 255, 255) 30%, transparent 50%),
    conic-gradient(from 180deg at 50% 50%, #33ccff, #a64bf4, #33ccff);
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;
}

.void-icon::before {
  content: "";
  position: absolute;
  width: 80%;
  height: 80%;
  border-radius: 50%;
  background: radial-gradient(
    circle,
    rgba(255, 255, 255, 0.5),
    rgba(255, 255, 255, 0)
  );
  z-index: 1;
}

.void-icon::after {
  content: "";
  position: absolute;
  width: 60%;
  height: 60%;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.1);
  z-index: 2;
}

.title-description {
  font-size: 0.8rem;
  color: rgba(255, 255, 255, 0.7); /* Un color blanco translúcido */
}
