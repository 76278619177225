.cSearchNavBarBtn {
  width: 100%;
  background: linear-gradient(
    90deg,
    #a64bf4,
    #33ccff00
  ); /* Degradado de púrpura a azul */
  color: white;
  outline: none;
  border: none;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Sombra suave */
  transition: all 0.3s ease;
}

.cSearchNavBarBtn:focus {
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2);
}

.cSearchNavBarBtn::placeholder {
  color: rgba(255, 255, 255, 0.7); /* Placeholder de color blanco translúcido */
}
