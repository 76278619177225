@keyframes opacityAnimDef {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.opacityAnim {
  animation-duration: 1s;
  animation-name: opacityAnimDef;
}

@keyframes transitionBottomDef {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0%);
  }
}

.transitionBottom {
  animation-duration: 2s;
  animation-name: transitionBottomDef;
}

.bgDark {
  background-color: #212529;
  height: 100vh;
  width: 100vw;
  z-index: -100;
}

.movieExpand {
  transition: 0.5s;
  box-shadow: 0px 0px 1rem rgb(0, 0, 0);
}

.movieExpand:hover {
  transform: scale(1.2);
  box-shadow: 0rem 0rem 4rem blanchedalmond;
}

.movieModalBg {
  background-color: #3f3f3f8e;
  z-index: 100;
  height: 100vh;
  width: 100vw;
}

.movieModal {
  background-color: #fefefe;
  /*max-height: 90vh;*/
  width: 16rem;
  margin-top: 5vh;
  animation-duration: 1s;
  animation-name: opacityAnimDef;
}

.blurEffect {
  filter: blur(4px);
  -webkit-filter: blur(4px);
}

@media (max-width: 992px) {
  .w-lg-less-100 {
    width: 100% !important;
  }
}

.c-rounded {
  border-radius: 16px;
}

.c-bg-primary {
  background: linear-gradient(90deg, #a54bf4, #33ccff00) !important;
  border-radius: 1rem;
  padding: 2rem 0.5rem;
}

.c-bg-primary-full {
  background: linear-gradient(90deg, #a54bf4, #33ccff) !important;
  border-radius: 1rem;
  padding: 2rem 0.5rem;
}
