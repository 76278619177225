@keyframes heroOpacityAnimDef {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.heroOpacityAnim {
  animation-duration: 1.5s;
  animation-name: heroOpacityAnimDef;
}
